import React from "react";
import { Layout } from "@startuponsale/gatsby-shared-ui";
import { Link } from 'gatsby';

const NewsletterPage = () => {
  return (
    <div>
      <Layout>
        <div className="w-full bg-white">
          <div className="max-w-4xl mx-auto px-4 pt-20 pb-40">
              <p className="text-center text-sm sm:text-base text-dpurple font-normal NexaTextDemoLight pb-16">Your submission was received!</p>
              <p className="text-center text-sm sm:text-base text-dpurple font-normal NexaTextDemoLight pb-16">
              <Link
                to="/"
                className=" bg-dpink w-full transition-all hover:shadow-xl hover:bg-pink-700 rounded-lg text-white px-10 py-4 cursor-pointer"
              >
                back home
              </Link>
              </p>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default NewsletterPage;
